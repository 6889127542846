/* eslint-disable no-script-url */
import React, {useState, useRef} from "react";
import {Link} from "gatsby";
import Formsy from "formsy-react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Captcha from "../components/captcha";
import fetchin from "../logic/utils/fetch";
import {forgotPasswordMutation} from "../logic/user";
import FormsyTextInput from "../components/formsy/text-field";
import Manager from "../components/manager";
import Theme from "../configs/theme";
import sysConfig from "../configs/system.json";

export default function ForgotPassword(props) {
  const {disableForm, headerImage} = props;
  const captchaRef = useRef(null);
  const [state, setState] = useState({
    showTerms: false,
    showPrivacy: false,
    error: false,
    isSubmitted: false,
    message: "",
    loading: false,
  });

  async function handleSubmit({email, captcha, captchaKey}) {
    const result = await fetchin.post(`${sysConfig.baseUri}rest.api/forgot-request`, {
      email,
      captcha,
      captchaKey
    });
    console.log("result", result);
    // if (!result.data.classMethods.User.forgotPassword) {
    //   if(captchaRef.current) {
    //     await captchaRef.current.refresh();
    //   }
    //   if((result.errors || []).length > 0) {
    //     return setState({
    //       error: true,
    //       message: result.errors[0].message,
    //     });
    //   }

    //   return setState({
    //     error: true,
    //     message: `${email} does not exist in the system`,
    //   });
    // }
    
    return setState({
      error: false,
      success: true,
    });
  }

  return (
    <Manager name="login" title="Forgot Password">
      <Theme>
        {state.success ? (
          <Container className="form-spacer">
            {(headerImage && headerImage !== "") && (
              <Row>
                <Col>
                  <img alt="" src={headerImage} />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <div className="alert alert-success">
                  {"An email has been sent containing the reset password link."}
                </div>
              </Col>
            </Row>
            <Row className="login-button-row">
              <Col>
                <Link className="register-link" to="/">{"Back to Login"}</Link>
              </Col>
            </Row>
          </Container>
        ) : (
          <Formsy className="frm-login" onValidSubmit={handleSubmit}>
            <Container className="form-spacer">
              {(headerImage && headerImage !== "") && (
                <Row>
                  <Col>
                    <img alt="" src={headerImage} />
                  </Col>
                </Row>
              )}
              <Row style={{marginBottom: 30}}>
                <Col xs={12}>
                  <div className="login-title">
                    {"Password Reset"}
                  </div>
                </Col>
              </Row>
              {state.error && (
                <Row>
                  <Col>
                    <div className="alert alert-danger">
                      {state.message}
                    </div>
                  </Col>
                </Row>
              )}
              <Row className="field-row">
                <Col>
                  <FormsyTextInput
                    name="email"
                    type="email"
                    validations={"isEmail"}
                    validationError="A valid email address is required"
                    label="Email Address"
                    placeholder="Email Address"
                    testid="email"
                    disabled={state.isSubmitted}
                    validationError="This field is required"
                    required
                  />
                </Col>
              </Row>
              <Row className="field-row">
                <Col>
                  <Captcha name="captcha" />
                </Col>
              </Row>
              <Row className="login-button-row">
                {/* <Col className="ml-auto" xs={"auto"}>
                  <Button
                    type={disableForm ? "button" : "submit"}
                    style={{width: "100%"}}
                    className="btn-submit btn-forgot"
                    colour="primary">
                    {"Forgot Password"}
                  </Button>
                </Col> */}
              </Row>
              <hr />
              <Row className="login-button-row">
                <Col>
                  <Link className="register-link" to="/">
                    {"Back to Login"}
                  </Link>
                </Col>
              </Row>
            </Container>
          </Formsy>
        )}
      </Theme>
    </Manager>
  );
}
